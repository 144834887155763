import { jsonFetchForm } from '../functions/http'
import { disableSaveBtn } from '../functions/form'
import { addEventListeners, replaceChildNodes } from '../functions/dom'

export class AjaxForm extends HTMLFormElement {
  connectedCallback () {
    disableSaveBtn(true)
    this.disableBtn()
    this.addEventListener('submit', this.onSubmit.bind(this))
    this.dispatchEvent(new CustomEvent('load'))
  }

  disableBtn () {
    addEventListeners(
      this.querySelectorAll('input[type="checkbox"]'),
      'change',
      () => disableSaveBtn(false)
    )
    addEventListeners(
      this.querySelectorAll('input[type="text"]'),
      'input',
      () => disableSaveBtn(false)
    )
    addEventListeners(
      this.querySelectorAll('input[type="email"]'),
      'input',
      () => disableSaveBtn(false)
    )
    addEventListeners(this.querySelectorAll('input[type="tel"]'), 'input', () =>
      disableSaveBtn(false)
    )
    addEventListeners(
      this.querySelectorAll('input[type="hidden"]'),
      'change',
      () => disableSaveBtn(false)
    )
    addEventListeners(this.querySelectorAll('select'), 'change', () =>
      disableSaveBtn(false)
    )
    addEventListeners(this.querySelectorAll('textarea'), 'input', () =>
      disableSaveBtn(false)
    )
    addEventListeners(
      this.querySelectorAll('input[type="number"]'),
      'change',
      () => disableSaveBtn(false)
    )
  }

  async onSubmit (e) {
    e.preventDefault()
    disableSaveBtn(true)
    const croppie = this.querySelector('[is=croppie-image-editor]')
    if (croppie) {
      const croppieValue = croppie.value
      croppie
        .result()
        .then(async () => this.send())
        .catch(() => (croppie.value = croppieValue))
    } else {
      await this.send()
    }
  }

  async send () {
    try {
      await jsonFetchForm(this.action, {
        method: this._method?.value ?? this.method,
        body: new FormData(this)
      })
      this.dispatchEvent(
        new CustomEvent('success', {
          detail: {
            info: new FormData(this)
          }
        })
      )
    } catch (data) {
      const modal = document.querySelector('ajax-modal')
      if (modal) {
        await modal.createFragment(data)
      } else {
        const fragment = document
          .createRange()
          .createContextualFragment(data)
          .querySelector('form')
        replaceChildNodes(this, fragment)
        this.connectedCallback()
      }

      this.dispatchEvent(new Event('failed'))
    }
  }
}
